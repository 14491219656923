import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"
import { Box, Container, Flex, Grid, Heading, Image, Link } from "theme-ui"
import Breadcrumbs from "../components/breadcrumbs"
import { Calendar } from "react-feather"
import calendar from "../assets/images/calendar.svg"
import pin from "../assets/images/pin.svg"

const EventHero = ({ page }) => (
  <Box
    sx={{ position: "relative", backgroundColor: page.primaryColor.hex, mb: 4 }}
  >
    <Box
      sx={{
        position: "absolute",
        top: 0,
        left: 0,
        height: "100%",
        width: "100%",
        ".gatsby-image-wrapper": { height: "100%" },
        overflow: "hidden",
      }}
    >
      <GatsbyImage image={page.heroImage.gatsbyImageData} />
    </Box>
    <Box
      sx={{
        position: "absolute",
        top: [null, null, 0],
        bottom: [0, 0, null],
        right: 0,
        height: ["60%", "60%", "100%"],
        width: ["100%", "100%", "40%"],
        backgroundColor: page.primaryColor.hex,
        overflow: "hidden",
        mixBlendMode: "overlay",
      }}
    />
    <Box
      sx={{
        position: ["relative", "relative"],
      }}
    >
      <Container sx={{ paddingY: [5, 6, 7, 8], position: "relative" }}>
        <Grid columns={[1, 1, "1.5fr 1fr", "2fr 1fr"]} gap={[5, 6, 7, 9]}>
          <Container>
            <Flex>
              <Image src={page.text.url} />
            </Flex>
          </Container>
          <Box>
            <Image sx={{ mb: [4, 5, 7, 8] }} src={page.logo.url} />
            <Box
              sx={{
                p: {
                  color: "light",
                  fontSize: [2, 3],
                  fontWeight: 500,
                  mt: [0],
                  mb: [3, 4],
                },
                img: {
                  minWidth: "32px",
                  mr: [2, 3],
                },
              }}
            >
              <Flex sx={{ alignItems: "flex-start" }}>
                <Image src={calendar} />
                <Box dangerouslySetInnerHTML={{ __html: page.date }} />
              </Flex>
              <Flex sx={{ alignItems: "flex-start" }}>
                <Image src={pin} />
                <Box dangerouslySetInnerHTML={{ __html: page.location }} />
              </Flex>
              <Link
                href={page.registrationLink}
                variant="button"
                target="_blank"
                sx={{
                  mt: [4, 5],
                  display: "inline-block",
                  backgroundColor: "light",
                }}
              >
                {page.registrationCta}
              </Link>
            </Box>
          </Box>
        </Grid>
      </Container>
    </Box>
  </Box>
)

export default EventHero
